<template>
  <div class="navigate-box">
    <template v-if="ready">
      <wx-open-launch-weapp :username="username" :path="path" v-html="wolwHtml">
      </wx-open-launch-weapp>
    </template>
    <template v-else>
      <div class="wx-btn" :id="`wxapp`">
        <slot></slot>
      </div>
    </template>
  </div>
</template>

<script>
import wxSdk from "@/utils/wxsdk";
export default {
  props: {
    username: String,
    path: {
      type: String,
      default() {
        return "pages/index/index";
      },
    },
  },
  data() {
    return {
      ready: false,
      wolwTemplate:
        '<template type="text/wxtag-template"><div class="wx-btn">{{slot}}</div></template>',
      wolwHtml: null,
    };
  },
  mounted() {
    wxSdk.navigateWxMP().then(() => {
      let ht = document.getElementById("wxapp").innerHTML;
      this.wolwHtml =
        '<template style="display:block!important;width:100%;"><div class="wx-btn">' +
        ht +
        "</div></template>";
      this.ready = true;
    });
  },
};
</script>

<style>
.wx-btn{
    width: 100%!important;
    height: 30px!important;
}
</style>