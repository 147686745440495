<template>
    <div @click="clickTest" class="things-selecter-item" :style="{backgroundColor:color}">
        {{text}}
    </div>
</template>

<script>
export default {
  model: {
    prop: 'color',
    event: 'parent-event'
  },
  props: {
    text: String,
    color: {
      type: String,
      default () {
        return '#FFFFFF'
      }
    }
  },
  methods: {
    clickTest () {
      console.log('hi')
    }
  }
}
</script>

<style>
.things-selecter-item {
  width: 1.5rem;
  height: 1.5rem;
  text-align: center;
  line-height: 1.5rem;
  border: 1px solid gray;
  border-radius: 0.5rem;
  margin: 0.2rem;
}
</style>
