<template>
    <div class="block-box">
        <div class="block-title">
            <div class="block-title-left">实物位置</div>
            <div class="block-title-right"><a :href="`https://api.map.baidu.com/marker?location=${x},${y}&title=${title}&content=山东理工大学教育发展基金会&output=html`">
                    <svg-icon icon-class="circle-plus" /> 详细地图</a>
            </div>
        </div>
        <div class="block-content" v-if="y && x">
            <baidu-map class="map-box" style="display: flex; flex-direction: column"
                :center="{lng: y, lat: x}" :zoom="17">
                <bm-marker :position="{lng: y, lat: x}">
                    <bm-label :content="title" :labelStyle="{color: 'red', fontSize : '0.5rem'}"
                        :offset="{width: 0, height: 30}" />
                </bm-marker>
                <bm-view style="width: 100%; flex: 1"></bm-view>
            </baidu-map>
        </div>
    </div>
</template>

<script>
export default {
  props: { x: Number, y: Number, title: String },
  mounted () {}
}
</script>

<style>
.map-box {
  width: 100%;
  height: 200px;
}
</style>
