<template>
  <div class="donate-box">
    <van-action-sheet
      v-model="showSelecterBox"
      :title="selecterTypeTitle"
      :round="false"
    >
      <div class="donate-selecter">
        <div class="donate-selecter-container-i">
          <div class="donate-info">
            <div class="donate-info-img">
              <img class="project-item-img" v-lazy="mediaUrl" alt="media" />
            </div>
            <div class="donate-info-text">
              <p class="donate-info-title">{{ title }}</p>
              <p class="donate-info-amount">
                ￥{{ inputAmount ? inputAmount : 0 }}
              </p>
            </div>
          </div>
          <van-divider />
          <template v-if="type === 1">
            <div class="donate-selecter-item">
              <van-row>
                <van-col
                  span="8"
                  v-for="selecter in selecters"
                  :key="selecter.ref"
                >
                  <div
                    class="selecter-item-button"
                    @click="changeSelecter(selecter.ref)"
                    :ref="selecter.ref"
                  >
                    {{ selecter.title }}
                  </div>
                </van-col>
              </van-row>
            </div>
            <div>
              <van-row>
                <van-col span="18">
                  <van-field
                    class="donate-selecter-input"
                    ref="templatex"
                    @focus="changeSelecter('templatex')"
                    v-model="inputAmount"
                    type="number"
                    label-width="20px"
                    label="￥"
                    placeholder="自定义金额"
                  />
                </van-col>
                <van-col
                  span="6"
                  class="donate-selecter-random"
                  @click="randomAmount"
                >
                  <van-icon name="replay" /> 随机</van-col
                >
              </van-row>
            </div>
          </template>
          <template v-if="type === 2">
            <div class="donate-things-list">
              <div
                class="donate-things-item"
                v-for="(item, idx) in thingsList"
                :key="idx"
              >
                <div class="things-item-image">
                  <img
                    class="project-item-img project-item-slient-img"
                    v-lazy="item.image"
                    alt="media"
                  />
                  <div class="project-item-slient-text">
                    <p>查看相册</p>
                  </div>
                  <div class="project-item-slient">
                    <silent-box :gallery="images">
                      <template v-slot:silentbox-item>
                        <div class="project-item-slient-cover"></div>
                      </template>
                    </silent-box>
                  </div>
                </div>
                <div class="things-item-info">
                  <div class="things-item-title">
                    <p class="things-item-name">{{ item.name }}</p>
                    <p class="things-item-price">￥{{ item.price }}</p>
                  </div>
                  <div class="things-item-description">
                    <p>{{ item.description }}</p>
                  </div>
                  <div class="things-item-tools">
                    <van-row>
                      <van-col :span="5">共有: {{ item.total }}</van-col>
                      <van-col :span="5">剩余: {{ item.remain }}</van-col>
                      <van-col :span="5">已选: {{ item.selected }}</van-col>
                      <!--van-col :span="6">
                        <van-button @click="openThingSelecter(1)" round type="info"
                          size="mini" class="things-tools-button" color="red">认捐</van-button>
                      </van-col-->
                      <van-col :span="9">
                        <van-button
                          v-if="item.selected === 0"
                          @click="thingsSelectHandle(idx)"
                          round
                          type="info"
                          size="mini"
                          class="things-tools-button"
                          color="red"
                          >我要认捐</van-button
                        >
                        <template v-else>
                          <van-stepper
                            v-model="item.selected"
                            :min="0"
                            :max="item.remain"
                            button-size="18px"
                            @change="updateInputAmount"
                          />
                        </template>
                      </van-col>
                    </van-row>
                  </div>
                </div>
              </div>
            </div>
          </template>
          <van-divider />
          <van-row>
            <van-col span="11" offset="1">
              <van-checkbox v-model="readNotice" icon-size="1rem"
                >已阅读<router-link to="/page/xieyi"
                  ><span style="text-decoration: solid !important"
                    >捐赠协议</span
                  ></router-link
                >
              </van-checkbox>
            </van-col>
            <van-col span="12">
              <van-checkbox
                v-if="type === 1"
                v-model="annoymousDonate"
                icon-size="1rem"
                >匿名捐赠</van-checkbox
              >
            </van-col>
          </van-row>
        </div>
        <van-row>
          <van-col span="20" offset="2">
            <van-button
              class="next-step"
              style="width: 100%"
              type="primary"
              round
              color="#FF0000"
              @click="nextStepAction"
              >下一步</van-button
            >
          </van-col>
        </van-row>
      </div>
    </van-action-sheet>
    <van-popup
      v-model="showThingSelecter"
      :style="{ width: '90%', height: '80%' }"
    >
      <div class="things-selecter-box">
        <div class="things-selecter-title">
          <p>选择您的认捐物品</p>
          <em
            @click="showThingSelecter = false"
            style="top: 0.5rem"
            class="van-icon van-icon-cross van-action-sheet__close"
          ></em>
        </div>
        <div class="things-selecter-tools">
          <van-row>
            <van-col :span="6" :offset="3">
              <van-button color="red" class="things-selecter-tools-button" round
                >确定</van-button
              >
            </van-col>
            <van-col :span="6">
              <van-button color="red" class="things-selecter-tools-button" round
                >随机</van-button
              >
            </van-col>
            <van-col :span="6">
              <van-button class="things-selecter-tools-button" round
                >重置</van-button
              >
            </van-col>
          </van-row>
        </div>
        <div class="things-button-info">
          <table>
            <tr>
              <td>
                <things-item color="white" />
              </td>
              <td>
                <things-item color="yellow" />
              </td>
              <td>
                <things-item color="red" />
              </td>
              <td>
                <things-item color="green" />
              </td>
            </tr>
            <tr>
              <td><span class="into-label">可选择</span></td>
              <td><span class="into-label">已锁定</span></td>
              <td><span class="into-label">不可选</span></td>
              <td><span class="into-label">已选择</span></td>
            </tr>
          </table>
          <div>
            <p><svg-icon icon-class="total" /> 限选：5</p>
            <p><svg-icon icon-class="selected" /> 已选：1</p>
          </div>
        </div>
        <div class="things-selecter-item-container">
          <div
            class="things-selecter-item-box"
            :style="{
              width: backgroundSize.width,
              height: backgroundSize.height,
              backgroundImage: `url(${backgroundImage})`,
            }"
          >
            <things-item />
          </div>
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
import ThingsItem from "@/components/ThingsItem";
import { Notify } from "vant";
import { getProjectSelecter } from "@/api/project";

export default {
  components: { ThingsItem },
  data() {
    return {
      testBoolen: false,
      color: "red",
      inputAmount: null,
      title: null,
      readNotice: true,
      showThingSelecter: false,
      annoymousDonate: false,
      showSelecterBox: this.show,
      selecterTypeTitle: "现金捐赠",
      thingsSelectData: { amount: 0 },
      selecters: [],
      randomList: [],
      mediaUrl: null,
      things: [[]],
      images: [
        /*
        {
          src:
            "https://foundation.isdut.cn/wp-content/uploads/2020/12/2020120800211259159.jpg",
          description: "Star Night Sky Ravine by Mark Basarab, from Unsplash.",
          alt: "Blue starry night photo.",
          thumbnailWidth: "220px",
        },
        {
          src:
            "https://foundation.isdut.cn/wp-content/uploads/2020/12/2020120800444872184.jpg",
          description: "Corno Nero, Italy by Luca Zanon, from Unsplash.",
          alt: "Landscape photo of mountain with fog.",
          thumbnailWidth: "220px",
        },*/
      ],
      backgroundImage:
        "https://foundation.isdut.cn/wp-content/uploads/2020/12/2020120800444872184.jpg",
      backgroundSize: { width: 1080 + "px", height: 721 + "px" },
      thingsSelectAmount: 0,
      selectedThings: [],
      thingsList: [],
      type: 1,
    };
  },
  props: {
    show: Boolean,
    project: Number,
  },
  watch: {
    showSelecterBox(newValue) {
      this.$emit("update:show", newValue);
    },
    show(newValue) {
      this.showSelecterBox = newValue;
    },
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    fetchData() {
      getProjectSelecter(this.project).then((response) => {
        console.log(response);
        this.type = response.type;
        this.title = response.title;
        this.mediaUrl = response.banner;
        if (this.type === 1) {
          this.selecterTypeTitle = "现金捐赠";
          this.selecters = response.selecters;
          this.randomList = response.random;
        }
        if (this.type === 2) {
          this.selecterTypeTitle = "实物认捐";
          this.thingsList = response.things;
        }
      });
    },
    changeSelecter(ref) {
      let selecterList = [];
      let selecterValue = [];
      this.selecters.forEach((selecter) => {
        selecterList.push(selecter.ref);
        selecterValue.push(selecter.amount);
      });
      selecterList.forEach((item, index) => {
        let style = this.$refs[item][0].style;
        if (item === ref) {
          style.color = "red";
          style.borderColor = "red";
          this.inputAmount = selecterValue[index];
        } else {
          style.color = "#323233";
          style.borderColor = "#2c3e50";
        }
      });
    },
    randomAmount() {
      this.inputAmount = this.randomList[
        Math.floor(Math.random() * this.randomList.length)
      ];
    },
    nextStepAction() {
      if (this.inputAmount === 0) {
        Notify({ type: "warning", message: "请选择或填写捐赠金额" });
        return;
      }
      if (!this.readNotice) {
        Notify({ type: "warning", message: "请阅读捐赠协议" });
        return;
      }
      if (
        this.inputAmount.toString().search(/^([1-9]\d{0,9}|0)(\.\d{1,2})?$/) === -1
      ) {
        Notify({ type: "warning", message: "无效捐赠金额" });
        return;
      }
      if (this.inputAmount <= 0) {
        Notify({ type: "warning", message: "无效捐赠金额" });
        return;
      }

      let donate = {
        pid: this.project,
        amount: this.inputAmount,
        annoymous: this.annoymousDonate ? 1 : 0,
        group: this.$route.query.group,
        inviter: this.$route.query.inviter,
      };
      if (this.type === 2) {
        let _selectedList = [];
        this.thingsList.forEach((item) => {
          if (item.selected > 0) {
            _selectedList.push({
              tid: item.tid,
              price: item.price,
              selected: item.selected,
              amount: item.price * item.selected,
              name: item.name,
            });
          }
        });
        donate.selected = JSON.stringify(_selectedList);
      }

      this.$router.push({
        name: "DonateForm",
        query: donate,
      });
    },
    openThingSelecter() {
      this.showThingSelecter = true;
    },
    onItemClick() {
      this.color = "blue";
    },
    thingsSelectHandle(tid) {
      this.thingsList[tid].selected = 1;
      this.updateInputAmount();
    },
    updateInputAmount() {
      let _newAmount = 0;
      this.thingsList.forEach((item) => {
        if (item.selected > 0) {
          _newAmount += item.selected * item.price * 100;
        }
      });
      this.inputAmount = _newAmount / 100;
    },
  },
};
</script>

<style>
.donate-selecter {
  min-height: 50% !important;
  max-height: 80% !important;
}

.donate-selecter-container-i {
  padding: 0.5rem;
}

.donate-info {
  height: 6rem;
  width: 100%;
  display: flex;
}

.donate-info-img {
  width: 11rem;
  display: block;
}

.donate-info-img img {
  height: 6rem;
  width: 10rem;
  border: 0.03rem solid #eaeef0;
  border-radius: 0.3rem;
}

.donate-info-text {
  margin: 0.5rem 0.5rem;
  width: 11rem;
  flex: 1;
}

.donate-info-text p {
  margin: 0;
  text-align: left;
}

.next-step {
  width: 100%;
  bottom: 0;
}

.donate-selecter-item {
  height: 2rem;
  margin: 0.2rem 0;
}

.selecter-item-button {
  border: #2c3e50 0.08rem solid;
  border-radius: 0.3rem;
  width: 85%;
  height: 1.5rem;
  margin: 0 auto;
  font-size: 1rem;
  line-height: 1.5rem;
  text-align: center;
}

.donate-selecter-input {
  border: #2c3e50 0.08rem solid;
  border-radius: 0.3rem;
  font-size: 1rem;
  height: 2rem;
  padding: 0.2rem !important;
  margin: 0.2rem 0.54rem;
}

.donate-selecter-random {
  line-height: 2rem;
  margin: 0.2rem 0;
  padding-left: 1rem;
  font-size: 1rem;
}

.donate-info-title {
  font-size: 1.1rem;
  font-weight: bold;
  width: 100%;
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.donate-info-amount {
  margin-top: 1rem !important;
  font-size: 2rem;
  color: red;
}

.donate-things-item {
  height: 5rem;
  margin: 0.2rem 0.5rem;
  display: flex;
  border: 1px solid #f5f5f5;
}

.things-item-image {
  width: 5rem;
  height: 5rem;
}

.things-item-image > img {
  width: 5rem;
  height: 5rem;
}

.things-item-info {
  flex: 1;
  padding: 0.2rem 1rem;
}

.things-item-info p {
  margin: 0;
}

.things-item-title {
  height: 1.5rem;
}

.things-item-title p {
  font-size: 1rem;
  line-height: 1.5rem;
}

.things-item-name {
  float: left;
}

.things-item-price {
  float: right;
  color: red;
}
.things-item-description {
  height: 1.8rem;
}
.things-item-description p {
  font-size: 0.7rem;
  text-indent: 0.5rem;
  line-height: 0.9rem;
}

.things-item-tools {
  font-size: 0.7rem;
}

.things-item-tools .van-col {
  text-align: center;
}

.things-tools-button {
  height: 1rem !important;
  width: 100% !important;
}

.things-tools-button .van-button__text {
  height: 1rem !important;
  font-size: 0.7rem !important;
  width: 100% !important;
}

.things-selecter-box {
  padding: 0.5rem;
}

.things-selecter-title p {
  margin: 0;
  text-align: center;
  font-size: 1.3rem;
  line-height: 2rem;
}

.things-selecter-item-container {
  margin-top: 1rem;
  background-color: aquamarine;
  height: 10rem;
  overflow: scroll;
}

.project-item-slient {
  position: absolute;
  z-index: 3;
  width: 5rem;
  height: 5rem;
  overflow: hidden;
}

.project-item-slient-text {
  position: absolute;
  z-index: 2;
  width: 5rem;
  height: 5rem;
}

.project-item-slient-text p {
  margin: 0;
  text-align: center;
  position: relative;
  font-size: 0.5rem;
  margin-top: 4.3rem;
  line-height: 0.5rem;
  color: #f5f5f5;
}

.project-item-slient-img {
  position: absolute;
  z-index: 1;
}

.project-item-slient-cover {
  height: 5rem;
  width: 5rem;
}

.things-button-info {
  height: 3rem;
}

.things-button-info > div {
  width: 30%;
  float: right;
  margin-top: 0.2rem;
}

.things-button-info > div > p {
  margin: 0;
  line-height: 1.5rem;
}

.things-button-info table {
  width: 60%;
  margin: 0 auto;
  float: left;
  margin-left: 0.5rem;
}

.things-button-info td {
  text-align: center;
}

.things-button-info td > div {
  margin: 0 auto;
}

.things-button-info .info-label {
  font-size: 0.6rem;
}

.things-selecter-tools-button {
  font-size: 1rem;
  height: 1.5rem !important;
}

.things-selecter-tools {
  height: 2rem;
}

.things-selecter-tools .van-col {
  text-align: center;
  height: 1.5rem;
}

.things-selecter-tools span {
  margin: 0;
  line-height: 1.6rem;
}
</style>
